
                    @import "@/styles/utilities/_variables.scss";
                    @import "@/styles/utilities/_mixins.scss";
                    @import "@/styles/utilities/_functions.scss";
                
/* Disable number input up/down arrows for Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Disable number input up/down arrows for Firefox */
input[type=number] {
    -moz-appearance:textfield;
}
