
                    @import "@/styles/utilities/_variables.scss";
                    @import "@/styles/utilities/_mixins.scss";
                    @import "@/styles/utilities/_functions.scss";
                

/* Disable scrollbar jumping hack from Vuetify (https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#scrollbar-overflow) */
html {
    overflow-y: auto;
}
